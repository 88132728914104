export default {
  methods: {
    resize: function resize() {
      var top = this.$refs.top;
      $("#serve-top").css({
        height: 680 / 1920 * top.offsetWidth + "px"
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    //   this.resetPosition();
    this.resize();
    $(window).on(" resize", function () {
      _this.resize();
    });
  },
  unmounted: function unmounted() {
    $(window).unbind();
  }
};